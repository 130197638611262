<template>
  <div class="main-wrapper">
    <div class="page-wrapper">
      <Navbar />
      <slot />
      <Footer />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopOnlyLayout',
  components: {
    Footer: () => import('../components/Footer.vue'),
    Navbar: () => import('../components/Navbar.vue'),
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  .page-wrapper {
    width: 100%;
    margin-left: 0;

    /deep/ .navbar {
      left: 0;
      width: 100%;

      @media (max-width: 991px) {
        .navbar-content {
          width: 100%;
        }
      }
    }

    /deep/ .footer,
    /deep/.page-content {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}
</style>
